@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
}

.App-text-container{
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  padding-bottom: 12px;
  padding-top: 12px;
  margin-bottom: 80px;
  width: 100%;
  font-size: 18px;
  gap: 12px;

}

@media (max-width: 600px) {
  .App-text-container {
    margin-top: 25%;
  }
}

button {
  font-family: 'United Sans Rg Md' !important;
  font-size: 18px !important
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.App-main {
  height: 100%;
  background-image: url('./images/37\ Detail\ Monocromo\ 90x90\ Web.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}
